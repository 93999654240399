@import "../../../mixins.scss";

.Preferences {
  b {
    display: inline-block;
    font: 700 16px/24px $baseFont;
    margin: 30px 0;
    margin-top: 0;
    > small {
      display: block;
      line-height: 22px;
    }
  }
  p {
    margin: 0;
  }
  small {
    color: #a1a1a1;
    font: 13px/17px $baseFont;
  }
  > .field {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
    text-align: left;
    &:not(:last-child) {
      margin-bottom: 15px !important;
    }
    > label {
      font-weight: normal !important;
      line-height: 22px !important;
      margin: 0 !important;
    }
    .p-inputswitch {
      min-width: 3rem;
      margin-left: 20px;
    }
  }
}
