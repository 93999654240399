@import "../../../mixins.scss";
.ThemedInput > div {
  position: relative;
  & + sub {
    color: #e32222;
    display: block;
    font-family: $baseFont;
    height: 0;
    transform: translateY(-8px);
    transition: all 0.5s ease;
    opacity: 0;
    &.error {
      height: auto;
      opacity: 1;
      transform: translateY(0);
    }
  }
}
.ThemedInput {
  &.p-input-icon-left {
    .pi {
      position: absolute;
      left: 22px;
      top: 24px;
    }
    .input {
      padding-left: 45px;
    }
  }
  &.p-input-icon-right {
    .pi {
      position: absolute;
      right: 22px;
      top: 24px;
    }
    .input {
      padding-right: 45px;
    }
  }
}
.input {
  background: #f1f5f5;
  border: solid 2px transparent;
  border-radius: 5px;
  box-sizing: border-box;
  font: 15px/20px $baseFont;
  padding: 20px;
  width: 100%;
  &::placeholder {
    color: #8e8e8e;
  }
  &:-webkit-autofill {
    font-size: 15px !important;
  }
  &.error {
    border-color: #e32222;
  }
}
