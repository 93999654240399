@import "../../mixins.scss";

.ChatPanel {
  background: #fff;
  border-top: 1px solid #e5e5e5;
  color: #111;
  padding: 18px 0;
  text-align: center;
  user-select: none;
  > .wrapper {
    display: flex;
    margin: 0 auto;
    max-width: 660px;
    width: calc(100% - 30px);
    position: relative;
    > textarea {
      align-self: center;
      border: 0;
      border-left: solid 1px #e5e5e5;
      border-radius: 0;
      flex-grow: 1;
      height: 24px;
      font: 15px/24px $baseFont;
      min-width: auto;
      padding: 0 0 0 20px;
      resize: none;
      @include for-size(tablet-landscape-up) {
        padding: 0 20px;
      }
      &::-webkit-input-placeholder,
      &-ms-input-placeholder,
      &::placeholder {
        color: #a1a1a1;
      }
      &:disabled {
        background: transparent;
      }
    }
    > .input-prefix {
      position: absolute;
      align-self: center;
      left: 65px;
      line-height: 24px;
      font: 15px/24px $baseFont;
      & + textarea {
        padding-left: 30px;
      }
    }
  }
}

.btn-menu,
.btn-emoji {
  align-self: center;
  background: #f8f8f8;
  border-radius: 50%;
  color: #111;
  font-size: 24px;
  height: 24px;
  width: 24px;
}

.btn-menu {
  margin-right: 20px;
  .p-badge {
    min-width: 1rem;
    height: 1rem;
    line-height: 1rem;
    padding: 0 0.2rem;
  }
}

.btn-send {
  display: flex;
  align-self: center;
  flex-direction: column;
  border-left: solid 1px #e5e5e5;
  color: $primaryColor;
  font: bold 14px/24px $baseFont !important;
  margin-left: 20px;
  padding-left: 20px;
  text-transform: uppercase;
  > span {
    color: #6b6b6b;
    opacity: 0.5;
    font: bold 9px/18px $baseFont !important;
  }
  &.disabled {
    color: #6b6b6b;
    cursor: not-allowed;
    opacity: 0.5;
  }
}
.emoji-wrapper {
  position: relative;
  top: -18px;
}
.menu {
  position: relative;
  align-self: center;
  > div {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    transition: all 250ms linear;
    transform: translateY(100%);
    &.open {
      transform: translateY(0%);
    }
    @include for-size(tablet-landscape-up) {
      bottom: 40px;
      display: none;
      left: 12px;
      position: absolute;
      right: auto;
      &.open {
        display: block;
        transform: translateX(-50%);
        z-index: 100;
      }
    }
    > ul {
      background: #fff;
      border-radius: 40px 40px 0 0;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      color: $brandColor;
      list-style: none;
      margin: 0;
      padding: 15px 50px;
      text-transform: uppercase;
      @include for-size(tablet-landscape-up) {
        background: rgba(17, 17, 17, 0.9);
        border-radius: 4px;
        color: #fff;
        padding: 10px 0;
        position: relative;
        text-transform: none;
        &:after {
          border: solid transparent;
          border-color: rgba(136, 183, 213, 0);
          border-top-color: rgba(17, 17, 17, 0.9);
          border-width: 6px;
          content: " ";
          height: 0;
          left: 50%;
          margin-left: -6px;
          pointer-events: none;
          position: absolute;
          top: 100%;
          width: 0;
        }
      }
      > li {
        border-bottom: solid 1px #f6f6f6;
        cursor: pointer;
        font: bold 12px/40px $baseFont;
        letter-spacing: 0.05em;
        min-width: 150px;
        padding: 10px 0;
        white-space: nowrap;
        &:last-child {
          border: 0;
          color: #8e8e8e;
          margin-top: 32px;
        }
        @include for-size(tablet-landscape-up) {
          border: 0;
          font: 13px/30px $baseFont;
          letter-spacing: 0;
          padding: 0 15px;
          &:last-child {
            display: none;
          }
          &:hover {
            background: #333;
          }
        }
      }
    }
  }
}
