@import "../../../mixins.scss";

@keyframes slide-left {
  from {
    transform: translateX(-476px);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-right {
  from {
    transform: translateX(476px);
  }
  to {
    transform: translateX(0);
  }
}

.modal {
  background: rgba(0, 0, 0, 0.55);
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1000;

  @include for-size(tablet-landscape-up) {
    overflow: auto;
  }
  &.no-animate > .inner {
    animation: none !important;
  }
  > .inner {
    align-self: center;
    background: #fff;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    height: 100%;
    margin: auto;
    min-height: 100%;
    position: absolute;
    width: 100%;

    &.right {
      .shown > h1 {
        display: none;
      }
    }

    @include for-size(tablet-landscape-up) {
      &.right {
        header {
          display: none;
        }
        .shown > h1 {
          display: block;
          margin: 40px 0 0;
        }
      }
    }

    > div {
      align-items: center;
      display: flex;
      flex-direction: column;
      flex-grow: 1;
      opacity: 0;
      overflow-y: auto;
      transition: all 1s;

      &.shown {
        opacity: 1;
      }

      &.contentHeader {
        > header {
          background-color: #fff;
          display: none;
          height: 70px;
          left: 0;
          padding: 40px 40px 0;
          position: absolute;
          right: 0;
          top: 0;
          z-index: 1;
        }
        @include for-size(tablet-landscape-up) {
          margin-top: 100px;
          > header {
            display: block;
            > h1,
            > span {
              display: block;
              text-align: center;
            }
            > h1 {
              color: #111;
              font: bold 30px/30px $baseFont;
              margin: 0 0 10px 0;
            }
            > span {
              color: #8e8e8e;
              font: 14px/20px $baseFont;
            }
          }
        }
      }

      .field {
        position: relative;
        &:not(:last-child) {
          margin-bottom: 30px;
        }
      }

      h1 {
        color: #111;
        font: bold 30px/30px $baseFont;
        text-align: center;
        margin-top: 0;
        margin-bottom: 30px;
        @include for-size(tablet-landscape-up) {
          margin-bottom: 40px;
        }
      }

      label:not(.p-dropdown-label, .p-field-checkbox label) {
        color: #111;
        display: inline-block;
        font: bold 15px/15px $baseFont;
      }

      label:not(.p-dropdown-label, .p-field-checkbox label, .field-checkbox label) {
        margin-bottom: 12px;
      }
    }

    > footer {
      border-top: solid 1px #e5e5e5;
      display: flex;
      min-height: 60px;
      > * {
        color: #8e8e8e;
        flex: 1;
        font: bold 14px/60px $baseFont;
        letter-spacing: 0.05em;
        line-height: 18px;
        margin: 0;
        position: relative;
        text-transform: uppercase;
        &:not(:last-child)::after {
          content: "";
          position: absolute;
          top: 0;
          bottom: 0;
          border-right: solid 1px #e5e5e5;
          right: 0;
        }
        &.primary {
          color: $brandColor !important;
        }
        &.disabled {
          cursor: initial;
        }
      }
    }

    &.left {
      left: 0;
      > button.close-button {
        right: -76px;
      }
    }
    &.right {
      right: 0;
      > button.close-button {
        left: -76px;
      }
    }
    @include for-size(tablet-landscape-up) {
      max-width: 400px;
      &.left {
        animation: 0.5s slide-left;
        border-radius: 0 20px 20px 0;
      }
      &.right {
        animation: 0.5s slide-right;
        border-radius: 20px 0 0 20px;
      }
    }

    > ul {
      margin: -20px 0 0;
      list-style: none;
      border-bottom: solid 1px #e4e4e4;
      text-transform: uppercase;
      font: 13px $baseFont;
      color: rgba(36, 36, 36, 0.4);
      min-height: 56px;
      padding: 0 30px;

      @include for-size(tablet-landscape-up) {
        padding: 0 60px;
      }

      > li {
        display: inline-block;
        margin-left: 40px;
        border-color: transparent;
        cursor: pointer;
        padding: 20px 0;
        position: relative;
        font: 13px $baseFont;

        &.active {
          color: $brandColor;
          font-weight: 500;
          &:before {
            height: 0px;
            border-bottom: solid 3px;
            content: "";
            position: absolute;
            bottom: -1px;
            left: 0;
            right: 0;
          }
        }

        &:first-child {
          margin-left: 0;
        }
      }
    }

    > header {
      align-items: center;
      background: #fff;
      border-radius: 0 0 20px 20px;
      box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.1);
      box-sizing: content-box;
      display: flex;
      flex-basis: 0;
      justify-content: space-between;
      min-height: 40px;
      padding: 15px;
      z-index: 100;

      @include for-size(tablet-landscape-up) {
        // display: none;
      }

      > button {
        background: #efefef;
        border-radius: 50%;
        height: 40px;
        width: 40px;
        > i {
          font-size: 40px;
        }
        &.invisible {
          visibility: hidden;
          width: 40px;
        }
      }

      > div {
        > h1,
        > span {
          display: block;
          text-align: center;
        }
        > h1 {
          color: #111;
          font: bold 15px/40px $baseFont;
          margin: 0;
        }
        > span {
          color: #8e8e8e;
          font: 14px/18px $baseFont;
        }
      }
    }

    > button.close-button {
      background: none;
      border: 0;
      color: #4c4c4c;
      cursor: pointer;
      font-size: 1.2em;
      height: 76px;
      line-height: 1;
      padding: 30px;
      position: absolute;
      user-select: none;
      width: 76px;
      > i {
        color: #fff;
        display: block;
        font-size: 50px;
        line-height: 76px;
        margin-left: -17px;
        margin-top: -31px;
      }
    }
  }
}

// Fix styles for primereact version downgrade
.p-datepicker td > a,
.p-datepicker td > span {
  text-align: center !important;
  line-height: 0.8rem !important;
}

.p-inputswitch .p-inputswitch-slider::before {
  top: 0.86rem !important;
}
