@import "../../../mixins.scss";

.page-not-found {
    .error-block {
        height: 100vh;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 0;

        >p {
            margin: 10px;
            font-size: 20px;
            color: #c1d6d5;
        }

        .error-code {
            font-size: 40px;
            color: $brandColor;
        }
    }
}

@include for-size(tablet-portrait-up) {
    .page-not-found {
        background: #fff url("../../../assets/maintenance.svg") no-repeat center;
        background-size: contain;
        height: 800px;

        .error-block {
            height: 100%;
            align-items: flex-start;
            margin-left: 30px;

            >p {
                font-size: 30px;
            }

            .error-code {
                font-size: 60px;
            }
        }
    }
}

@include for-size(desktop-up) {
    .page-not-found {
        .error-block {
            align-items: center;
            width: 300px;

            >p {
                font-size: 50px;
            }

            .error-code {
                font-size: 80px;
            }
        }
    }
}