@import "../../mixins.scss";

.InvoiceNew {
  font-family: $baseFont;
  overflow: auto;
  padding: 40px 20px 40px 50px;
  background-color: #fff;

  @include form-button();

  h1 {
    margin: 0 0 20px 0;
    line-height: 1.3em;

    @include iconBackInTitle();
  }

  &__form {
    &__lineItems {
      border-bottom: 1px solid #dce7e7;
      padding-bottom: 25px;
      margin-bottom: 25px;

      > button {
        margin-left: 8.7%;
      }

      .delete {
        flex: 1;
        cursor: pointer;

        &.disabled {
          opacity: 0.5;
          cursor: auto;
        }

        &:after {
          content: "\2715";
          display: inline-block;
          color: $brandColor;
          font-size: 20px;
        }

        &.no-icon:after {
          content: "";
        }
      }

      .tb {
        font-weight: 700;
      }

      &__item {
        display: flex;
        padding: 8px 0;

        .GmInputText > input.p-inputtext.p-component,
        .GmInputNumber > span > input.p-inputtext.p-component,
        .GmCalendar .p-calendar > input,
        .GmInputTextarea > textarea,
        .GmCalendar .p-calendar,
        .p-dropdown > * {
          padding: 12px 4px;
          font-size: 13.5px !important;
          background-color: #f1f5f5;

          &:disabled:not([name$=".total"]) {
            background-color: #f8fafa;
          }
        }

        .GmCalendar .p-calendar {
          background-color: transparent;
        }

        .p-dropdown,
        .GmCalendar .p-calendar {
          padding: 0;
        }

        .GmInputTextarea > textarea {
          min-height: 70px;
          padding: 6px 4px;
        }

        > div {
          flex: 10;
          font-size: 14px !important;
          padding-right: 6px;
        }

        .index {
          flex: 2;
          text-align: center;
        }

        .description {
          flex: 9;
        }

        .unit {
          flex: 6;
        }

        .amount {
          flex: 4;
        }

        .qty {
          flex: 3;
        }

        .dates {
          flex: 7;
        }

        .total {
          flex: 4;
        }

        &:nth-child(n + 3) {
          .index {
            line-height: 1.9em;
          }
        }
      }
    }

    &__bottom {
      &.grid {
        margin: 0;
        font-size: 14px;

        .InputSwitchWrapper {
          display: flex;
          margin-bottom: 15px;
          align-items: center;

          .p-inputswitch {
            margin-right: 1rem;
            width: 48px;
          }

          label {
            max-width: 400px;
            flex: 1;
          }
        }

        .href {
          color: $brandColor;
          font-weight: 700;
          cursor: pointer;

          span {
            font-weight: inherit;
            color: #8e8e8e;
          }
        }

        table {
          tr {
            td {
              padding: 10px 20px 8px;
              font-size: 14px;
            }

            td:nth-child(1) {
              text-align: right;
              position: relative;
            }

            td:nth-child(2) {
              background: #f1f5f5;
              font-size: 15px;
              font-weight: 700;
              min-width: 120px;
            }
          }
        }

        label[for="notes"] {
          font-weight: 700;
          color: #000;
          margin-bottom: 10px;
          display: block;
        }
      }
    }
  }
}

.PhoneLineItem {
  font-size: 13px;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #e5e5e5;
  line-height: 1.3em;
  margin-bottom: 20px;

  label {
    color: #8e8e8e;
    display: block;
    margin-bottom: 6px;
  }

  > div {
    width: 33.33333%;
    margin-bottom: 20px;
  }

  .detail {
    font-weight: bold;
    font-size: 14px;
    width: 100%;
  }

  .qty {
    text-align: center;
  }

  .total {
    text-align: right;
  }

  .unit,
  .total {
    color: $brandColor;
  }

  .unit,
  .qty,
  .total {
    span {
      font-weight: 700;
      font-size: 14px;
    }
  }

  .wd2 {
    width: 66.66666%;
  }
}

@media screen and (max-width: 1080px) {
  .InvoiceNew {
    padding: 100px 30px 30px;
    line-height: 1.3em;
    &__form {
      &__lineItems {
        .p-button.inverse {
          width: 100%;
          margin: 0;
          margin-right: 24px;
        }

        @include iconBack();

        &__item {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100vh;
          padding: 115px 30px 30px;
          z-index: 1;
          flex-flow: row wrap;
          align-items: flex-start;
          align-content: flex-start;
          background-color: #fff;
          flex-direction: row-reverse;

          &.active-line-item {
            display: flex;
          }

          label {
            display: block;
            font-weight: 700;
            margin-bottom: 10px;
          }

          > div {
            flex: none !important;
            width: 50%;
            margin-bottom: 25px;
          }

          .p-dropdown,
          .GmCalendar .p-calendar {
            padding: 0 !important;
          }

          > h1 {
            flex: 1;
          }

          .delete,
          .index {
            display: none;
          }

          .dates {
            width: 100%;
          }

          .description {
            order: 1;
            width: 100%;

            textarea {
              min-height: 90px;
            }
          }

          .unit {
            width: 100%;
            order: 2;
          }

          .start_date {
            order: 4;
          }

          .end_date {
            order: 3;
          }

          .amount {
            order: 7;
          }

          .qty {
            order: 6;
          }

          .total {
            order: 10;
            width: 30%;

            label {
              text-align: right;
            }

            input {
              background-color: #fff !important;
              color: $brandColor;
              opacity: 1;
              font-weight: 700 !important;
              text-align: right;
            }
          }

          .phone-add-lineitem {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;
            border-top: 1px solid #dcdcdc;
            padding: 25px 0;
            color: $brandColor;
            margin-bottom: 0;
            font-weight: 700;
            text-transform: uppercase;

            &.disabled {
              opacity: 0.6;
            }
          }
        }

        .delete {
          float: right;
          position: relative;
          margin-top: -5px;
        }
      }

      &__bottom.grid {
        .InputSwitchWrapper {
          display: flex;

          > div {
            order: 2;
            margin-left: 15px;
          }
          > label {
            order: 1;
          }
          .p-inputswitch {
            min-width: 48px;
          }
        }

        > div {
          width: 100%;
          margin-bottom: 25px;
        }

        > div:nth-child(1) {
          order: 1;
        }

        > div:nth-child(2) {
          order: 3;
          padding: 25px 0;
          border: 1px solid #dce7e7;
          border-left: none;
          border-right: none;
        }

        > div:nth-child(3) {
          order: 2;
        }

        > div:nth-child(4) {
          order: 10;
        }

        table {
          tr {
            td:nth-child(1) {
              padding-left: 0;
              padding-right: 10px;
              position: relative;
            }
            td:nth-child(2) {
              background-color: transparent;
              padding-right: 0;
              padding-left: 10px;
              min-width: auto;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1081px) {
  .InvoiceNew__form__lineItems__item {
    label {
      display: none;
    }
  }
}
