@import "../../../mixins.scss";

.GmSelectModal {
  .p-button.p-component.input-like {
    height: auto;
    background: #f1f5f5 !important;
    border: solid 2px transparent !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    box-sizing: border-box !important;
    font: 15px/20px "Metropolis", Helvetica, Arial, sans-serif !important;
    margin-bottom: 10px;
    text-align: left;
    padding: 20px;
    width: 100%;
    span.p-button-label,
    span.p-button-text {
      color: #000;
      position: relative;
      &:after {
        color: #d4272b;
        content: "\e902";
        font-family: "primeicons";
        transform: rotate(-90deg);
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    span.p-button-text {
      padding: 0;
      line-height: 38px;
    }
  }
  &.error {
    sub,
    label {
      color: $fieldErrorBorderColor !important;
      font-family: $baseFont !important;
    }
  }
}
