@import "../../../mixins.scss";

@keyframes slide-right-mobile {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slide-left {
  from {
    transform: translateX(-476px);
  }

  to {
    transform: translateX(0);
  }
}

@include for-size(tablet-landscape-up) {
  .mobile-only {
    visibility: hidden;
  }
}

.ProfileModal {
  .password-update {
    display: flex;
  }

  > .inner > div > form {
    padding: 30px;

    @include for-size(tablet-landscape-up) {
      padding: 40px;
    }
  }

  &:not(.PersonalProfileModal, .TheWorkModal, .GettingPaidModal, .NotificationsModal, .UserProfileModal)
    > .inner
    > div {
    justify-content: center;

    @media screen and (max-height: 900px) {
      justify-content: unset;
      padding: 30px 0;
    }
  }

  .profile-avatar {
    border: solid 1px #cbcbcb;
    border-radius: 50%;
    box-shadow: inset 0 0 0 1px #cbcbcb;
    color: #111;
    display: block;
    font-size: 120px;
    height: 120px;
    margin: 0 auto;
    width: 120px;
  }

  .profile-name {
    font: bold 30px/30px $baseFont;
    margin: 30px auto 10px !important;
  }

  .profile-email {
    font: 15px/1.5 $baseFont;
    margin: 0 auto;
  }

  .profile-name,
  .profile-email {
    color: #111;
    max-width: 300px;
    overflow: hidden;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  .button.outline {
    border: solid 1px #e5e5e5;
    border-radius: 5px;
    color: $brandColor;
    display: none;
    font: bold 14px/14px $baseFont;
    letter-spacing: 0.05em;
    margin: 40px auto 0;
    padding: 23px 28px;
    text-transform: uppercase;
    width: 320px;

    @include for-size(tablet-landscape-up) {
      display: block;
    }
  }
}
