@import "../../../mixins.scss";

.NoticePeriodModal {
  div > h1 {
    display: none;
    line-height: 24px;
  }

  .NoticePeriodForm {
    padding: 0 40px;

    .red-bordered {
      margin: 0;
    }
  }
}
