@import "../../../mixins.scss";

.SelectBooking {
  .inner .shown {
    align-items: stretch;
  }
  p {
    font-family: $baseFont;
    margin: 0;
  }
  .bookings-grid {
    display: grid;
    grid-row-gap: 10px;
    margin: 30px;
  }
  .bookings-grid__item {
    min-height: 160px;
    border-radius: 10px;
    background-color: #f1f5f5;
    padding: 20px;
    display: grid;
    grid-template-columns: 35px auto;
    border: 1px solid transparent;
    transition: border-color 0.3s ease;
    position: relative;
    &.loading {
      opacity: 0.5;
      pointer-events: none;
    }
    &.selected {
      background-color: #fff;
      border: solid 1px $brandColor;
    }
    &.marked {
      text-decoration: line-through;
    }
    &:hover {
      border: solid 1px $brandColor;
      cursor: pointer;
    }
    .lds-ring {
      position: absolute;
      left: 0px;
      bottom: 15px;
    }
    .client-brief {
      grid-column: 2 / 4;
      font-size: 14px;
      line-height: 21px;
      .client {
        font-weight: 700;
      }
    }
    .date-start,
    .date-end {
      font-size: 13px;
      line-height: 19.5px;
      align-self: flex-end;
    }
    .date-start {
      grid-column: 2;
    }
    .date-end {
      grid-column: 3;
    }
    .date-title {
      color: #8e8e8e;
    }

    .action-menu {
      position: absolute;
      right: 20px;
      top: 20px;
    }
  }
  .p-radiobutton .p-radiobutton-box {
    border-color: $primaryColor;
    .p-radiobutton-icon {
      border: 3px solid white;
      background-color: $primaryColor;
      width: 16px;
      height: 16px;
    }
    &.p-highlight {
      background-color: $primaryColor;
      border-color: $primaryColor;
      &:not(.p-disabled):hover {
        background-color: $primaryColor;
        border-color: $primaryColor;
      }
    }
  }
}
.modal.SelectBooking {
  overflow: hidden;
  > .inner.right header {
    display: flex;
    flex-direction: column;

    > div h1 {
      font-size: 18px;
    }
  }

  .bookings-grid {
    a,
    p {
      font-size: 14px;
    }

    a {
      color: $brandColor;
      text-decoration: none;
      font-weight: 700;
    }
  }
}

.booking-action-buttons {
  &.p-menu {
    width: 215px;
  }
  .p-menuitem {
    padding: 5px;
  }
  &-item {
    display: flex;
    font-family: "Metropolis", Helvetica, Arial, sans-serif;
    cursor: pointer;
    > span {
      font-size: 14px;
      margin-left: 7px;
    }
  }
}
