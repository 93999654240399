.GmAutoComplete {
  .p-autocomplete {
    width: 100%;
  }
  .p-inputtext {
    background: #f1f5f5;
    border: solid 2px transparent;
    border-radius: 5px;
    box-shadow: none !important;
    box-sizing: border-box;
    font: 15px/20px "Metropolis", Helvetica, Arial, sans-serif;
    padding: 20px;
    width: 100%;
    &:hover,
    &:focus {
      border-color: #f1f5f5 !important;
    }
  }
}
