@import "../../../mixins.scss";

.MultiSelectModal {
  ul {
    box-sizing: border-box;
    margin: 0;
    padding: 30px;
    list-style: none;
    flex-grow: 1;
    overflow-y: auto;
    width: 100%;
    @include for-size(tablet-landscape-up) {
      padding: 40px;
    }

    li {
      background: $lightRedColor;
      border: 0;
      border-radius: 10px;
      color: $brandColor;
      cursor: pointer;
      font: bold 15px/24px $baseFont;
      margin-bottom: 10px;
      padding: 7px 15px;
      text-align: center;
      user-select: none;
      word-break: break-word;
      &.selected {
        background: $brandColor;
        border-color: $brandColor;
        color: #fff;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
