@import "../../../mixins.scss";

.GmInputTextarea {
  > textarea {
    background: #f1f5f5;
    border: solid 2px transparent;
    border-radius: 5px;
    box-shadow: none !important;
    box-sizing: border-box;
    font: 15px/20px $baseFont;
    max-width: 100%;
    min-width: 100%;
    padding: 20px;
    width: 100% !important;
    min-height: 170px;
    &::placeholder {
      color: #8e8e8e;
    }
    &:-webkit-autofill {
      font-size: 15px !important;
    }
  }
  &.error {
    > textarea {
      border-color: $fieldErrorBorderColor;
    }
    > label,
    > sub {
      color: $fieldErrorBorderColor !important;
      font-family: $baseFont !important;
    }
  }
  .charCount {
    color: #8e8e8e;
    font: 75%/20px $baseFont;
  }
}
