@import "../../mixins.scss";

.Toaster {
  height: 0;
  left: 50%;
  position: fixed;
  right: 50%;
  top: 0;
  width: 0;
  z-index: 10001;
  > div {
    position: absolute;
    top: 0;
    max-width: 50%;
    min-width: 100vw;
    transform: translate(-50%, 60px);
    @include for-size(tablet-landscape-up) {
      min-width: 450px;
    }
  }
  .p-messages {
    border: 0 none;
    color: #212121;
    margin: 1em 0;
    border-radius: 3px;
    &.p-messages-success {
      background-color: #b7d8b7;
    }
    &.p-messages-info {
      background-color: #7fbcec;
    }
    &.p-messages-warn {
      background-color: #ffe399;
    }
    &.p-messages-error {
      background-color: #f8b7bd;
    }
  }
  @include for-size(phone-only) {
    .p-message {
      max-width: 98vw;
      margin-left: 1vw;
    }
  }
}

.popup-item {
  cursor: pointer;
}

.popup-container {
  a {
    color: $primaryColor;
    font-weight: bold;
    text-decoration: none;
  }
  &:after {
    content: "";
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
    width: 100vw;
    height: 100vmax;
    top: 0;
    left: 0;
    z-index: 1104;
  }

  .container {
    position: fixed;
    background-color: #fff;
    z-index: 1105;
    width: 624px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 60px 60px 0 60px;
    border-radius: 20px;

    .content {
      text-align: center;
      margin-bottom: 40px;
      font: 15px/20px $baseFont;
      color: #333;
    }

    footer {
      min-height: 20px;
      margin: 0 -60px;

      &.isFetching {
        button {
          opacity: 0.2;
        }
      }

      button {
        background-color: transparent;
        border: 0;
        border-top: 1px solid #ececec;
        border-radius: 0;
        padding: 20px;
        cursor: pointer;
        text-transform: uppercase;
        font: 700 14px Metropolis, Helvetica, Arial, sans-serif;
        width: 100%;
        &.two {
          width: 50%;
        }
      }

      button:first-child {
        border-right: 1px solid #ececec;
        color: #8e8e8e;
        border-bottom-left-radius: 20px;
      }

      button:last-child {
        color: $brandColor;
        border-bottom-right-radius: 20px;
      }
    }
    .pi-exclamation-triangle {
      font-size: 3rem;
    }

    @include for-size(phone-only) {
      width: 100%;
    }
  }
}
