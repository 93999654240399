@import "../../../mixins.scss";

.success-info {
  background: #fff;
  color: green;
  font: 11px/1.3 "Metropolis", Helvetica, Arial, sans-serif;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  border-radius: 30px;
}

@keyframes fadeout {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

.fadeout {
  animation: 1s linear 2s 1 forwards fadeout;
}

.button.outline {
  position: relative;
}

.modal > .inner .profile-card {
  border: solid 1px #dce7e7;
  border-radius: 10px;
  width: calc(100% - 80px);
  position: relative;

  @include iconEdit();

  > div {
    padding: 16px 20px 18px;

    > h1 {
      font: 700 17px/17px "Metropolis", Helvetica, Arial, sans-serif;
      margin-bottom: 9px;
      text-align: left;
    }

    > p {
      font: 400 15px/15px "Metropolis", Helvetica, Arial, sans-serif;
      margin: 0;
    }
  }

  .profile-image {
    position: relative;
    height: 167px;
    padding: 0;
    background: #f0f5f5;
    font-family: "Metropolis", Helvetica, Arial, sans-serif;

    h3 {
      font-weight: bold;
      font-size: 20px;
      margin: 0 0 20px;
    }

    h4 {
      font-size: 14px;
      margin: 0;

      span {
        color: $brandColor;
      }
    }

    img {
      width: 100%;
      height: auto;
      min-height: 167px;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }

    &.is-default {
      padding: 20px 72px 10px 15px;

      img {
        position: absolute;
        bottom: 7px;
        right: 7px;
        width: 58px;
        height: auto;
        min-height: 0;
        opacity: 0.5;
      }
    }
  }
}

.profile-list {
  border: solid 1px #dce7e7;
  border-radius: 10px;
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 30px;
  width: calc(100% - 80px);

  > li {
    border-bottom: solid 1px #f1f5f5;
    cursor: pointer;
    font: 15px/22px "Metropolis", Helvetica, Arial, sans-serif;
    padding: 14px 20px;
    position: relative;
    user-select: none;

    &.screen::after {
      width: 10px;
      height: 10px;
      position: absolute;
      right: 20px;
      content: "\e914";
      color: $brandColor;
      font-family: "icomoon" !important;
    }

    > i {
      font-size: 24px;
      line-height: 24px;
      margin-right: 18px;
      vertical-align: middle;
    }

    &:last-child {
      border-bottom: 0;
    }

    a {
      text-decoration: none;
      color: #000;
    }
  }
}

.refer-connection {
  border: none;

  .refer-button {
    margin-top: 30px;
    min-height: 40px;
    padding: 7px 20px 7px 22px;
    font-family: "Metropolis", Helvetica, Arial, sans-serif;
    font-size: 15px;

    &-emoji {
      margin: 0 18px 1px 0;
    }

    .p-button-label {
      display: none;
    }

    > p {
      display: flex;
      align-items: center;
    }

    position: relative;

    justify-content: space-between;
    width: 100%;
    margin: 0;
    height: 50px;
    border-radius: 10px;
    font-weight: 400;

    &:before {
      content: "";
      position: absolute;
      background-image: url("../../../assets/refer-connection-bg.svg");
      background-repeat: no-repeat;
      background-position: 74% 10%;
      opacity: 0.2;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    &:after {
      font-size: 14px;
      margin-left: 15px;
      content: "\e914";
      color: #fff;
      font-family: "icomoon";
    }

    &:hover {
      &:after {
        color: $brandColor;
      }

      svg path {
        stroke: $brandColor;
      }
    }

    &:hover {
      svg path {
        stroke: $brandColor;
      }

      &:before {
        content: none;
      }
    }
  }
}

.trees {
  border: none;

  .planted-trees-button {
    min-height: 40px;
    position: relative;
    justify-content: space-between;
    width: 100%;
    margin: 0;
    height: 50px;
    border-radius: 10px;
    font-weight: 400;
    border-color: $lightGray;
    background: $lightGray;
    color: #111111;
    overflow: visible;
    padding: 7px 20px 7px 22px;
    font-family: "Metropolis", Helvetica, Arial, sans-serif;
    font-size: 15px;

    &:after {
      font-size: 14px;
      margin-left: 15px;
      content: "\e914";
      color: $green;
      font-family: "icomoon";
    }

    &:hover:enabled {
      background: #f1f5f5;
      color: #111111;
      border-color: #f1f5f5;
    }

    &:focus {
      box-shadow: none;
    }

    .p-button-label {
      display: none;
    }

    p {
      padding-left: 44px;
    }

    .trees-amount {
      padding-left: 0;
      margin-left: auto;
    }
  }

  .tree-image-wrap {
    position: absolute;
    left: 11px;
    bottom: 13px;
    width: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .trees-amount {
    color: $green;
    font-weight: bold;
  }
}

.logout {
  border: none;
  text-align: center;
  margin-top: 20px;

  li {
    font-weight: 700;
    text-transform: uppercase;
    color: $brandColor;

    &:hover {
      opacity: 0.8;
    }
  }
}

.availability {
  .pi-calendar {
    font-size: 22px;
  }

  li.screen {
    &:after {
      top: 0;
      bottom: 0;
      margin: auto;
      height: 23px;
    }

    & > p {
      display: inline-block;
      vertical-align: middle;
      margin: 0;

      span {
        display: block;
      }
    }
  }

  .status {
    font-size: 13px;
    line-height: 18px;
    color: #8e8e8e;
  }
}

.animated {
  animation: heartbeat 1.5s infinite;
  font-size: 26px;
  margin-left: 10px;
}

@keyframes heartbeat {
  0% {
    transform: scale(0.85);
  }

  20% {
    transform: scale(1);
  }

  40% {
    transform: scale(0.9);
  }

  55% {
    transform: scale(1);
  }

  80% {
    transform: scale(0.85);
  }

  100% {
    transform: scale(0.85);
  }
}
