@import "../../../mixins.scss";
.p-dropdown {
  background: #f1f5f5 !important;
  border: solid 2px transparent !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  box-sizing: border-box !important;
  font: 15px/20px "Metropolis", Helvetica, Arial, sans-serif !important;
  padding: 12px;
  width: 100%;
  > * {
    font: 15px/20px "Metropolis", Helvetica, Arial, sans-serif !important;
  }
  span.p-dropdown-label.p-inputtext {
    color: #111;
    white-space: normal;
  }
  .p-dropdown-filter-container {
    min-width: 100%;
  }
  .p-dropdown-panel .p-dropdown-filter-container .p-dropdown-filter-icon,
  .pi-chevron-down::before {
    color: $primaryColor;
  }
  .p-dropdown-label {
    color: #111;
  }
}
.GmDropdown.error {
  > .p-dropdown.p-component {
    border-color: $fieldErrorBorderColor;
  }
  > label,
  > sub {
    color: $fieldErrorBorderColor !important;
    font-family: $baseFont !important;
  }
}
