@import "../../../mixins.scss";

.InfluentialEmployerModal {
  .inner > div {
    justify-content: center;
    > form {
      padding: 40px;
      width: 100%;
    }
  }
}
