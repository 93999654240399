@import "../../mixins.scss";

aside.EmojiPanel {
  background: #fff;
  border-top: solid 1px #e3e3e3;
  bottom: 100%;
  left: 0;
  line-height: normal;
  height: 0;
  max-height: 50vh;
  overflow-y: hidden;
  position: absolute;
  right: 0;
  text-align: center;
  transition: all 0.5s ease;
  z-index: 100;

  &.shown {
    border-bottom: solid 1px #e3e3e3;
    height: 50vh;
  }

  @include for-size(tablet-landscape-up) {
    display: block;
  }

  > button {
    margin: 0 !important;
  }

  .emoji-mart-anchors,
  .emoji-mart-scroll {
    margin: 0 auto;
    max-width: 660px;
    width: calc(100% - 30px);
  }

  .emoji-mart-search {
    margin: 6px auto 0;
    max-width: 660px;
    width: calc(100% - 30px);
  }

  > .emoji-mart {
    border: 0 !important;
    display: block;
    height: 100%;
    width: 100% !important;
    > .emoji-mart-bar {
      button {
        border-radius: 0;
      }
    }
    > .emoji-mart-scroll {
      height: calc(100% - 82px);
    }
  }
}
