.BriefDatesDaysCount {
  &__stats {
    width: 290px;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin: 2em 0;

    i:not(.pi-plus) {
      font-size: 2em;
    }

    > div {
      text-align: center;
    }

    div > span {
      display: block;
      margin-top: 0.5em;
    }
  }
}

.wrp-confirm-dates {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
}
