@import "../../../mixins.scss";

.GmMultiCheckbox {
  .checkbox-box {
    &:hover {
      border-color: #bb1a1e !important;
    }
  }
  .field-checkbox {
    margin-top: 10px;
    &:last-child {
      margin-bottom: 0;
    }
  }
  .p-highlight {
    background-color: $primaryColor !important;
    border-color: $primaryColor !important;
    &:hover {
      background-color: #bb1a1e !important;
    }
  }
  label {
    font: normal 14px/22.4px "Metropolis", Helvetica, Arial, sans-serif;
    &:first-child {
      font-size: 17px;
    }
  }
  &.error {
    > textarea {
      border-color: $fieldErrorBorderColor;
    }
    > label,
    > sub {
      color: $fieldErrorBorderColor !important;
      font-family: $baseFont !important;
    }
  }
}
