@mixin for-size($range) {
  $phone-upper-boundary: 600px;
  $tablet-portrait-upper-boundary: 900px;
  $tablet-landscape-upper-boundary: 1200px;
  $desktop-upper-boundary: 1800px;

  @if $range == phone-only {
    @media (max-width: #{$phone-upper-boundary - 1}) {
      @content;
    }
  } @else if $range == tablet-portrait-up {
    @media (min-width: $phone-upper-boundary) {
      @content;
    }
  } @else if $range == tablet-landscape-up {
    @media (min-width: $tablet-portrait-upper-boundary) {
      @content;
    }
  } @else if $range == desktop-up {
    @media (min-width: $tablet-landscape-upper-boundary) {
      @content;
    }
  } @else if $range == big-desktop-up {
    @media (min-width: $desktop-upper-boundary) {
      @content;
    }
  }
}

@mixin form-button() {
  .p-button.p-component {
    padding: 21px 30px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    min-width: 150px;
    height: auto;
    justify-content: center;
    text-decoration: none;

    &.inverse {
      background-color: #fff;
      color: $brandColor;
      border: 1px solid #e5e5e5;

      &:hover {
        background-color: $brandColor;
        color: #fff;
      }
    }
  }
}

$baseFont: "Metropolis", Helvetica, Arial, sans-serif;
$baseEmojiFont: "Metropolis", Helvetica, Arial, apple color emoji, segoe ui emoji, noto color emoji, android emoji,
  emojisymbols, emojione mozilla, twemoji mozilla, segoe ui symbol, sans-serif;
$iconFont: "icomoon";
$primaryColor: #d4272b;
$toggleBg: #f1f5f5;
$brandColor: #d4272b;
$brandColorDarken: #b80015;
$lightRedColor: #f3ebec;
$fieldErrorBorderColor: #e32222;
$enabledSwitchColor: #368232;
$disabledSwitchColor: $primaryColor;
$green: #21a267;
$lightGray: #f1f5f5;

@mixin iconBack {
  .icon-back {
    position: absolute;
    top: 13px;
    left: 13px;
    font-size: 40px;
    background-color: #efefef;
    z-index: 1001;
    border-radius: 50%;
  }
}

@mixin iconBackInTitle {
  @include iconBack();

  a {
    position: relative;
    margin-top: -20px;
    color: #000;
    text-decoration: none;
  }

  .icon-back {
    position: relative;
    top: -8px;
    left: auto;
    z-index: 1;
  }
}

@mixin iconEdit {
  .icon-edit {
    font-size: 2.5em;
    border-radius: 2em;
    background: #fff;
    position: absolute;
    top: 0.2em;
    right: 0.2em;
    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 50%);
    cursor: pointer;
    z-index: 3;
  }
}
