@import "../../../mixins.scss";

.ProfileIntroductionModalSlider {
  user-select: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.55);
  z-index: 1000;
  .container {
    background: #fff url("../../../assets/genie-desktop-chat-bg.svg") no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 600px;
    border-radius: 30px;
    .slide {
      text-align: center;
      font-family: $baseFont;
      display: flex;
      flex-direction: column;
      margin: 60px 0;
      height: calc(100% - 120px);
      overflow-y: auto;
      overflow-x: hidden;
      &.slide-1,
      &.slide-5 {
        justify-content: center;
      }
      &.slide-5 {
        p {
          margin: 0 auto 15px;
        }
        .aboutDescription {
          font-style: italic;
          font-size: 0.95em;
        }
      }
      > * {
        margin: auto;
      }
      h1 {
        font-size: 30px;
        margin: 0 0 30px 0;
        padding: 0;
      }
      p {
        font-size: 17px;
        max-width: 600px;
        margin: 0 auto 20px;
      }
      ul,
      li {
        margin: 0;
        padding: 0;
        list-style: none;
      }
      ul {
        display: grid;
        grid-gap: 0;
        grid-template-columns: repeat(auto-fit, 350px);
        margin: 0 auto;
        width: 700px;
        li {
          text-align: left;
          font: normal 14px/22.4px $baseFont;
          position: relative;
          padding: 0 20px 15px 40px;
          margin-left: 20px;
          &:after {
            display: block;
            width: 10px;
            height: 10px;
            border-radius: 7px;
            content: "";
            border: 3px solid $primaryColor;
            position: absolute;
            left: 0;
            top: 4px;
          }
        }
      }
      &.slide-1 {
        .icon-user {
          color: #fff;
          background-color: #b80015;
          border-radius: 50%;
          font-size: 60px;
        }
      }
      &.slide-6 {
        ul {
          grid-template-columns: 100%;
        }
        .currentEmployerForm {
          grid-template-columns: repeat(auto-fit, 500px);
          justify-content: center;
          margin: 1rem auto 0;
          li {
            margin-left: 0;
            &:after {
              display: none;
            }
            .p-dropdown-items,
            .p-autocomplete-items {
              width: inherit;
            }
            .p-dropdown-item,
            .p-autocomplete-item {
              padding: 0.5rem 1rem;
            }
          }
        }
      }
      &.slide-7 {
        .briefPreferencesForm {
          margin: 1rem auto;
          padding: 1rem;
        }
        .field {
          display: flex;
          flex-direction: row-reverse;
          justify-content: space-between;
          align-items: center;
          text-align: left;
          label {
            margin-right: 2rem;
            > p {
              padding: 0;
              margin-bottom: 5px;
            }
            > small {
              color: #a1a1a1;
              font: 13px/17px "Metropolis", Helvetica, Arial, sans-serif;
              line-height: 22px;
              padding: 0;
            }
          }
          .p-inputswitch {
            min-width: 3rem;
          }
        }
      }
      &.slide-8,
      &.slide-9 {
        .field {
          margin: 1rem auto;
          text-align: left;
          width: 50%;
          span {
            width: 100%;
          }
        }
      }
      &.slide-10 {
        ul {
          display: block;
          max-width: 500px;
          margin: 30px auto;
        }
      }
    }
    a {
      text-transform: uppercase;
      color: #8e8e8e;
      text-decoration: none;
      font: bold 14px/14px $baseFont;
    }
    .controls {
      display: flex;
      width: 100%;
      padding: 15px 40px 15px;
      position: absolute;
      bottom: 0;
      > .col {
        flex: 3;
      }
      ul,
      li {
        margin: 0;
        padding: 0;
        list-style: none;
      }
      ul {
        text-align: center;
        li {
          font: bold 14px/50px $baseFont;
          width: 10px;
          height: 10px;
          border-width: 2px;
          border-color: #fff;
          border-style: solid;
          margin: 0;
          padding: 0;
          cursor: pointer;
          border-radius: 5px;
          margin-right: 8px;
          background-color: #dce7e7;
          display: inline-block;
          &.active {
            background-color: $primaryColor;
            border-color: $primaryColor;
          }
        }
      }
      .col-3 {
        text-align: right;
      }
      .navigation {
        a {
          color: $primaryColor;
          display: inline-block;
          padding: 4px 20px;
          &.disabled {
            color: #8e8e8e;
          }
        }
        :nth-child(2) {
          border-left: 1px solid #dce7e7;
        }
      }
    }
  }
  .close-button {
    background: none;
    border: 0;
    height: 76px;
    width: 76px;
    right: -76px;
    line-height: 1;
    padding: 20px;
    position: absolute;
    user-select: none;
    i {
      cursor: pointer;
      color: #fff;
      display: block;
      font-size: 50px;
      line-height: 76px;
      margin-left: -17px;
      margin-top: -31px;
    }
  }
}
@include for-size(phone-only) {
  .ProfileIntroductionModalSlider {
    background-color: #fff;
    .container {
      top: 0;
      left: 0;
      transform: none;
      width: 100%;
      height: 100%;
      .slide {
        height: calc(100% - 56px);
        &.slide-5 {
          justify-content: initial;
        }
        h1 {
          margin: 15px 0 15px 0;
        }
        ul {
          width: 100%;
          display: block;
        }
        p {
          padding: 0 20px;
        }
      }
      .controls {
        padding: 10px 10px 10px;
        .navigation {
          a {
            padding: 4px 20px 0 0;
          }
          .xs-hide {
            display: none;
          }
          :nth-child(2) {
            border: none;
          }
        }
        .col-1 {
          padding-left: 20px;
        }
      }
    }
  }
}
