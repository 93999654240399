@import "../../mixins.scss";

.PasswordRules {
  font: 13px/1.5 $baseFont;
  margin-bottom: 20px;
  ul {
    list-style: none;
    padding-left: 10px;
    > li {
      color: #8e8e8e;
      line-height: 21px !important;
      &::before {
        color: #8e8e8e;
        content: "\2022";
        display: inline-block;
        font-weight: bold;
        font-family: "icomoon";
        margin-right: 12px;
        text-align: center;
        vertical-align: bottom;
        width: 16px;
      }
    }
    > li.active {
      color: #111;
      &::before {
        color: #111;
        content: "\e90e";
        font-size: 9px;
      }
    }
  }
  & + .field {
    margin-bottom: 10px;
  }
}
