@import "../../../mixins.scss";

.PlatformsModal {
  &.preset {
    div > div > h1 {
      display: none !important;
    }
  }
  p {
    color: #111;
    display: block;
    font: 15px/23px $baseFont;
    text-align: center;
    &:first-of-type {
      margin: 30px 30px 0;
    }
    &:last-child {
      margin: 30px;
    }
    @include for-size(tablet-landscape-up) {
      &:first-child {
        margin: 4px 40px 40px;
      }
      &:last-child {
        margin: 40px;
      }
    }
  }
  ul {
    box-sizing: border-box;
    margin: 0;
    padding: 30px;
    list-style: none;
    flex-grow: 1;
    overflow-y: auto;
    width: 100%;
    @include for-size(tablet-landscape-up) {
      padding: 40px;
    }
  }
  .DropZone {
    box-sizing: border-box;
    padding: 30px;
    flex-grow: 1;
    overflow-y: auto;
    width: 100%;
    user-select: none;
    @include for-size(tablet-landscape-up) {
      padding: 40px;
    }
  }
  .platform {
    background: $lightRedColor;
    border: 0;
    border-radius: 10px;
    color: $brandColor;
    cursor: pointer;
    font: bold 15px/24px $baseFont;
    margin-bottom: 10px;
    padding: 7px 15px;
    text-align: center;
    user-select: none;
    word-break: break-word;
    * {
      user-select: none;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:first-child:not([data-value]):not(.prioritise) {
      background: #fff;
      border: solid 1px #e5e5e5;
      > input {
        background: none;
        border: 0;
        box-sizing: border-box;
        color: $brandColor;
        font: bold 15px/24px $baseFont;
        margin: 0;
        padding: 0;
        text-align: center;
        width: 100%;
        &::placeholder {
          color: $brandColor;
        }
        &:focus::placeholder {
          color: transparent;
        }
      }
    }
    &.selected {
      background: $brandColor;
      border-color: $brandColor;
      color: #fff;
    }
    &.prioritise {
      position: relative;
      padding-right: 2em;
      padding-left: 2em;
      > i {
        font-size: 2em;
        position: absolute;
        top: 4px;
        &.icon-menu {
          left: 0;
          color: #fff;
          &:before {
            color: #fff;
          }
        }
        &.icon-cancel {
          right: 0;
          cursor: pointer !important;
        }
      }
    }
  }
}
