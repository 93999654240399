@import "../../../mixins.scss";

.CalendarModal {
  font-family: "Metropolis", Helvetica, Arial, sans-serif;

  div > h1 {
    display: none;
    margin: 0 !important;

    @include for-size(tablet-landscape-up) {
      display: block;
      margin-top: 40px !important;
    }
  }

  h2 {
    text-align: center;
  }

  p {
    color: #111;
    display: none;
    font: 15px/23px $baseFont;
    margin: 4px 0 40px;
    text-align: center;
    visibility: hidden;

    @include for-size(tablet-landscape-up) {
      display: block;
    }
  }
}
