@import "../../../mixins.scss";

.lds-ring {
  display: block;
  position: relative;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  padding: 10px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    margin: 5px;
    border: 2px solid $brandColor;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $brandColor transparent transparent transparent;
  }
}
.btn-primary > .lds-ring div {
  border-color: #fff transparent transparent transparent !important;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
