@import "../../../mixins.scss";

.PlantedTreesModal {
  width: 700px;
  max-width: 100%;
  background: #fff;
  height: auto;
  border-radius: 30px;
  color: #000;
  text-align: center;
  &.p-dialog {
    border-radius: 30px;
    .p-dialog-header {
      background: transparent;
      padding: 30px 30px 0;
      border-top-right-radius: 30px;
      border-top-left-radius: 30px;
      color: #000;
      font-family: Metropolis, Helvetica, Arial, sans-serif;
      text-align: center;
      align-items: flex-start;
      .p-dialog-title {
        font-size: 30px;
        margin: 0 0 15px 0;
        padding: 0 0 0 32px;
        flex-grow: 1;
      }
      .p-dialog-header-icon:focus {
        box-shadow: 0 0 0 0.2rem $lightGray;
      }
    }
    .p-dialog-content {
      background: transparent;
      padding: 0 30px;
      flex-grow: 1;
      text-align: start;
    }
    .p-dialog-footer {
      background: transparent;
      border-bottom-right-radius: 30px;
      border-bottom-left-radius: 30px;
      padding: 0 30px 30px;
      margin-top: 15px;
      text-align: center;
      .ecologi-link {
        text-decoration: none;
        color: #111;
        display: inline-flex;
        align-items: center;
        height: 45px;
        color: $green;
        font: 700 18px/18px Metropolis, Helvetica, Arial, sans-serif;
      }
    }

    p {
      font-family: Metropolis, Helvetica, Arial, sans-serif;
      color: #000;
      font-size: 17px;
      max-width: 600px;
      margin: 0 auto 20px;
      line-height: 1.4;
    }
    hr {
      max-width: 600px;
      margin: 0 auto 20px;
    }
  }
  .share-link {
    word-break: break-all;
    cursor: pointer;
    color: #d4272b;
  }
  .ReferenceLink {
    word-break: break-all;
    color: #d4272b;
    text-decoration: none;
    font-weight: bold;
  }
  .trees {
    text-align: center;
    margin-bottom: 10px;
  }
}
