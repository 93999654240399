@import "../../mixins.scss";

.Maintenance {
  background: #fff url("../../assets/maintenance.svg") no-repeat;
  background-size: 150%;
  background-position-x: 105%;
  background-position-y: 40%;
  bottom: 0;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  z-index: 99999;
  padding: 0 33px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @include for-size(tablet-landscape-up) {
    background-position-x: left;
    background-position-y: center;
    background-size: contain;
    padding: 0 100px;
  }

  > img {
    margin: 0 auto 80%;
    width: 100px;
    @include for-size(tablet-landscape-up) {
      margin: 0;
      margin-bottom: 80px;
      width: 80px;
    }
  }

  > p {
    color: #111;
    font: 18px/28px $baseFont;
    text-align: center;
    @include for-size(tablet-landscape-up) {
      font: 20px/32px $baseFont;
      text-align: left;
      width: 436px;
    }
  }
}
