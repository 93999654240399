@import "../../../mixins.scss";

.ImageCropModal {
  .inner > div {
    justify-content: center;
  }
}
.ImageCropContainer {
  flex: 0;
  width: 100%;
  padding: 30px;
  .wide {
    margin: 0 -30px;
    .previewImage {
      min-width: 100%;
      height: auto;
    }
  }
  .invisible {
    margin-left: -6000px;
    position: absolute;
  }
  button {
    font-weight: 700;
    display: block;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #f6f6f6;
    height: 40px;
    color: #c1d6d5;
    &.primary {
      color: $primaryColor;
      margin-top: 20px;
    }
    &.upc {
      text-transform: uppercase;
    }
    &.rounded {
      border-radius: 10px;
      border: 1px solid $primaryColor;
    }
    &:hover {
      font-weight: 700;
    }
  }
  .p-inputtext {
    background: #f1f5f5;
    border-radius: 5px;
    font: 15px/20px Metropolis, Helvetica, Arial, sans-serif;
    width: 100%;
  }
  .msg {
    padding: 7px 0;
    font-family: $baseFont;
    &.msg-error {
      color: $fieldErrorBorderColor;
    }
  }
}
