@import "../../../mixins.scss";

.TheWorkForm {
  .p-inputswitch {
    float: right;
    & + label {
      line-height: 24px !important;
    }
  }
  .p-button.p-component {
    background: #fff;
    border: solid 1px $brandColor;
    border-radius: 10px;
    color: $brandColor;
    font: 700 15px/16px $baseFont;
    padding: 11px 1rem;
    width: 100%;
    &:hover {
      background: #fff;
      border: solid 1px $brandColor;
      color: $brandColor;
    }
  }
  .input-like {
    background: #f1f5f5 !important;
    border: solid 2px transparent !important;
    border-radius: 5px !important;
    box-shadow: none !important;
    box-sizing: border-box !important;
    font: 15px/20px "Metropolis", Helvetica, Arial, sans-serif !important;
    margin-bottom: 10px;
    text-align: left;
    padding: 12px;
    width: 100%;
    & + .p-button.p-component:not(.input-like) {
      margin-top: 10px;
    }
    span.p-button-label,
    span.p-button-text {
      color: #000;
      position: relative;
      &:after {
        color: #d4272b;
        content: "\e902";
        font-family: "primeicons" !important;
        transform: rotate(-90deg);
        position: absolute;
        right: 0;
        top: 0;
      }
    }
    span.p-button-text {
      padding: 0;
      line-height: 38px;
    }
  }
}
