.include-weekends {
  display: flex;
  align-items: center;
  padding-top: 10px;

  label {
    margin-bottom: 0 !important;
    margin-left: 15px;
  }
}
