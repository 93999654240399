@import "../../../mixins.scss";

.PersonalYouForm {
  width: 100%;

  .p-inputswitch {
    float: right;
    & + label {
      line-height: 24px !important;
    }
  }

  .p-selectbutton.p-buttonset.p-component {
    width: 100%;
  }

  #uk_work_permit + label {
    display: inline;
  }
}
