.GmSelectButton {
  background: #f1f5f5 !important;
  border: 0 !important;
  border-radius: 5px !important;
  box-shadow: none !important;
  box-sizing: border-box !important;
  font: 15px/30px "Metropolis", Helvetica, Arial, sans-serif !important;
  overflow: hidden;
  padding: 8px;
  width: 100%;
  > .p-button.p-component {
    background: transparent;
    border-color: transparent;
    border-radius: 4px !important;
    box-shadow: none !important;
    color: #8e8e8e;
    width: 50%;
    &.p-highlight {
      background: #fff !important;
      border-color: transparent;
      box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1) !important;
      color: #111;
      &:hover {
        background: #fff !important;
        border-color: transparent;
        color: inherit;
      }
    }
    &:hover {
      background: transparent !important;
      border-color: transparent !important;
    }
  }
}
