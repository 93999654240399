@import "../../../mixins.scss";

$boxShadow: 0 0 0 0.2rem rgba(red($brandColor), green($brandColor), blue($brandColor), 0.2);

.GmCalendar {
  .p-calendar {
    background: $lightGray;
    box-shadow: none !important;
    box-sizing: border-box;
    font: 15px/20px $baseFont;
    padding: 20px;
    width: 100%;

    > input {
      border: 2px solid transparent;
      background: transparent;
      padding: 0;
      border-radius: 5px;
      font: 15px/20px $baseFont;

      &:focus,
      &:active {
        box-shadow: none;
      }
    }

    &::placeholder {
      color: #8e8e8e;
    }

    &:-webkit-autofill {
      font-size: 15px !important;
    }
  }

  &.error {
    input.p-inputtext.p-component {
      border-color: $fieldErrorBorderColor;
    }

    label,
    sub {
      color: $fieldErrorBorderColor !important;
      font-family: $baseFont !important;
    }
  }

  .p-datepicker table td > span.p-highlight {
    color: #fff !important;
    background: $brandColor !important;
  }

  .p-datepicker table td > span:focus {
    box-shadow: 0 0 0 0.2rem lighten($brandColor, 50);
  }
}

.p-datepicker {
  .p-datepicker-group-container {
    table td > span,
    table td.p-datepicker-today > span {
      &.p-highlight {
        background-color: $lightRedColor;
      }

      &:focus {
        box-shadow: $boxShadow;
      }
    }

    .p-datepicker-header {
      .p-datepicker-prev,
      .p-datepicker-next {
        &:focus {
          box-shadow: $boxShadow;
        }
      }
    }

    &:not(.p-disabled) table td span:not(.p-highlight):not(.p-disabled):focus {
      box-shadow: $boxShadow;
    }
  }
}
