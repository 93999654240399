@import "../../../mixins.scss";

.GmFileUpload {
  font-family: $baseFont;
  a {
    color: $brandColor;
    text-decoration: none;
    font-weight: 600;
    padding: 11px 1rem 11px 0;
  }
  .preview {
    display: flex;
    margin-bottom: 1em;
    > a,
    > span {
      font-size: 0.8em;
      position: relative;
      max-width: 255px;
      width: 80%;
      overflow: hidden;
      &:after {
        position: absolute;
        right: 0;
        bottom: 0;
        height: 100%;
        width: 20%;
        content: "";
        background: linear-gradient(to left, white 20%, rgba(255, 255, 255, 0) 80%);
      }
    }
    button[type="button"] {
      width: auto;
      width: 20%;
    }
  }
  .description {
    color: #8e8e8e;
    font: 75%/20px "Metropolis", Helvetica, Arial, sans-serif;
  }
  > sub {
    color: #e32222 !important;
    font-family: "Metropolis", Helvetica, Arial, sans-serif !important;
  }
}
