@import "../../mixins.scss";

.SelectDateButton {
  color: $brandColor;
  display: block;
  font: bold 14px $baseFont;
  letter-spacing: 0.05em;
  line-height: 40px;
  padding-left: 40px;
  position: relative;
  text-transform: uppercase;
  &:hover {
    font-weight: bold;
  }
  > i {
    display: inline-block;
    font-size: 40px;
    left: 0;
    position: absolute;
    top: -1px;
    transform: scaleX(-1);
  }
}
