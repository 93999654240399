@import "../../mixins.scss";

.Header {
  background: rgba(255, 255, 255, 0.5);
  border-bottom: 1px solid #e5e5e5;
  color: #111;
  display: none;
  font: 600 18px/60px $baseFont;
  height: 60px;
  text-align: center;
  user-select: none;
  @include for-size(tablet-landscape-up) {
    display: block;
  }
}
