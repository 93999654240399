@import "../../mixins.scss";

.Chat {
  flex-grow: 1;
  overflow-y: auto;
  margin-top: 70px;

  @include for-size(tablet-landscape-up) {
    margin-top: 0;
  }

  > ul.messages {
    flex-grow: 1;
    min-height: 100%;
    list-style: none;
    margin: 0 auto;
    max-width: 540px;
    mix-blend-mode: multiply;
    padding: 0;
    width: calc(100% - 140px);

    @include for-size(tablet-landscape-up) {
      width: calc(100% - 150px);

      li:not(.me) > .avatar {
        left: -60px;
      }

      li.me > .avatar {
        right: -60px;
      }
    }

    li {
      padding: 5px 0;
      position: relative;

      &:not(.me) {
        > .avatar {
          left: -55px;
        }
      }

      &.group {
        padding-top: 0 !important;
      }

      &.last > .Chat__Message {
        border-bottom-left-radius: 10px;
      }

      > .avatar {
        background: transparent;
        border-radius: 50%;
        height: 40px;
        line-height: 40px;
        padding: 0;
        position: absolute;
        text-align: center;
        width: 40px;

        > img {
          width: 40px;
          height: 40px;
          display: block;
          margin: 0 auto;
        }
      }

      > .Day {
        color: $brandColor;
        font: bold 12px/1.5 $baseFont;
        text-align: center;
      }

      > .Chat__Message {
        background: #dce7e7;
        border-radius: 0 10px 10px 0;
        box-sizing: border-box;
        color: #111;
        display: inline-block;
        font: 15px/1.5 $baseEmojiFont;
        min-width: 80px;
        padding: 8px 20px 16px;
        position: relative;
        word-break: break-word;
        text-align: inherit;
        white-space: pre-wrap;

        > p {
          margin: 0;

          > svg {
            margin-top: 4px;
          }
        }

        > small {
          bottom: 3px;
          color: #8e8e8e;
          font-size: 0.6em;
          position: absolute;
          right: 8px;
          white-space: nowrap;

          > i {
            margin-left: 5px;
          }
        }

        .Attachments > img {
          max-width: 100%;
          height: auto;
        }

        &.informer {
          background: #add8e6;
          padding-right: 2.5rem;
          box-shadow: 0 0 4px -1px rgba(0, 0, 0, 0.2), 0 0 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12);
          border: 1px solid #8e8e8e;
          cursor: pointer;

          .pi-info-circle {
            position: absolute;
            right: 10px;
            top: 5px;
          }
        }
      }

      > .ButtonsGroup {
        .disabled,
        [disabled] {
          opacity: 0.5 !important;
          cursor: default;

          &:hover {
            cursor: default;
          }
        }

        .ChatButton {
          color: $brandColor;
          font: bold 14px $baseFont;
          letter-spacing: 0.05em;
          line-height: 40px;
          padding-left: 40px;
          position: relative;
          text-transform: uppercase;
          cursor: pointer;

          > i.icon-back {
            font-size: 40px;
            left: 0;
            position: absolute;
            transform: scaleX(-1);
          }

          &:hover {
            font-weight: bold;
          }
        }
      }
    }

    li.me {
      text-align: right;

      > .avatar {
        background: #fff;
        box-shadow: inset 0 0 0 1px #dfdfdf;
        right: -55px;

        > i {
          font-size: 40px;
          vertical-align: top;
        }
      }

      &.last > .Chat__Message {
        border-bottom-right-radius: 10px;
      }

      > .Chat__Message {
        background: rgba(255, 255, 255, 0);
        border: solid 1px $brandColor;
        border-radius: 10px 0 0 10px;
      }
    }
  }

  &__QuickReplies {
    > span {
      background: $lightRedColor;
      border: 0;
      border-radius: 10px;
      color: $brandColor;
      cursor: pointer;
      display: block;
      font: bold 15px/24px $baseFont;
      margin-top: 10px;
      padding: 7px 15px;
      text-align: center;
      user-select: none;
      word-break: break-word;

      &.disabled {
        color: #6b6b6b;
        cursor: not-allowed;
        opacity: 0.5;
      }

      @include for-size(tablet-landscape-up) {
        display: inline-block;
        margin-right: 10px;
        padding: 7px 25px;
      }
    }
  }

  .confetti {
    position: absolute;
    width: 150%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    @include for-size(phone-only) {
      width: 250%;
    }
  }
}
