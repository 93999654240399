@import "../../mixins.scss";

.SelectRebookButton {
  color: $brandColor;
  display: block;
  font: bold 14px $baseFont;
  letter-spacing: 0.05em;
  line-height: 40px;
  padding-left: 40px;
  position: relative;
  text-transform: uppercase;
  &:hover {
    font-weight: bold;
  }
  > i {
    display: inline-block;
    font-size: 40px;
    left: 0;
    position: absolute;
    top: -1px;
    transform: scaleX(-1);
  }
}

.RebookModal {
  .shown > div {
    width: calc(100% - 60px);
    @include for-size(tablet-landscape-up) {
      width: calc(100% - 80px);
    }
  }
  p {
    color: #111;
    display: block;
    font: 15px/23px $baseFont;
    text-align: center;

    &:first-of-type:not(.hint) {
      margin: 30px 30px 0;
    }
    &:last-child:not(.hint) {
      margin: 30px;
    }
    @include for-size(tablet-landscape-up) {
      &:first-child {
        margin: 4px 40px 40px;
      }
      &:last-child:not(.hint) {
        margin: 40px;
      }
    }
  }
  ul {
    box-sizing: border-box;
    margin: 0;
    padding: 30px;
    list-style: none;
    flex-grow: 1;
    overflow-y: auto;
    width: 100%;
    @include for-size(tablet-landscape-up) {
      padding: 40px;
    }
    li {
      position: relative;
      &:first-child:not([data-value]) {
        border-radius: 10px;
        margin-bottom: 10px;
        padding: 7px 15px;
        background: #fff;
        border: solid 1px #e5e5e5;
        > input {
          background: none;
          border: 0;
          box-sizing: border-box;
          color: $brandColor;
          font: bold 15px/24px $baseFont;
          margin: 0;
          padding: 0;
          text-align: center;
          width: 100%;
          &::placeholder {
            color: $brandColor;
          }
          &:focus::placeholder {
            color: transparent;
          }
        }
      }
      .umbrellas-list {
        margin: 0;
        padding: 0.5rem 0;
        position: absolute;
        background-color: #fff;
        left: 0;
        top: 40px;
        z-index: 10;
        font: 15px/23px $baseFont;
        box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%);
        li {
          transition: box-shadow 0.2s;
          border: none !important;
          padding: 0.5rem 1rem !important;
          margin: 0 !important;
          border-radius: 0 !important;
          color: #495057;
          cursor: pointer;
          &:hover {
            background: #e3f2fd;
          }
        }
      }
    }
  }
  .talent {
    background: $lightRedColor;
    border: 0;
    border-radius: 10px;
    color: $brandColor;
    cursor: pointer;
    font: bold 15px/24px $baseFont;
    margin-bottom: 10px;
    padding: 7px 15px;
    text-align: center;
    user-select: none;
    word-break: break-word;
    * {
      user-select: none;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:first-child:not([data-value]) {
      background: #fff;
      border: solid 1px #e5e5e5;
      > input {
        background: none;
        border: 0;
        box-sizing: border-box;
        color: $brandColor;
        font: bold 15px/24px $baseFont;
        margin: 0;
        padding: 0;
        text-align: center;
        width: 100%;
        &::placeholder {
          color: $brandColor;
        }
        &:focus::placeholder {
          color: transparent;
        }
      }
    }
    &.selected {
      background: $brandColor;
      border-color: $brandColor;
      color: #fff;
    }
  }

  .ThemedInput {
    margin-top: 1em;
    margin-bottom: 1em;
  }

  .p-selectbutton {
    text-align: center;
    .p-button {
      border: 0;
      font-size: 1rem;
      border-radius: 5px;
      margin: 2px;
      .p-button-text {
        padding: 0.25em 0.5em;
      }
      &.p-highlight {
        background-color: $brandColor;
        &:hover {
          background-color: $brandColor;
        }
      }
      &.p-button:not(:first-of-type):not(:last-of-type) {
        border-radius: 5px;
      }
      &:hover,
      &:active,
      &:focus {
        box-shadow: none;
      }
    }
  }
}
