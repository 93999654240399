@import "../../../mixins.scss";

.GmRadioButton {
  .field-radiobutton {
    margin-bottom: 0;
    > label {
      margin: 12px;
    }
  }
  .p-radiobutton-icon {
    border: 3px solid white;
    background-color: $primaryColor !important;
    width: 16px !important;
    height: 16px !important;
  }
  .p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
    box-shadow: none;
  }
  .p-highlight {
    background-color: $primaryColor !important;
    border-color: $primaryColor !important;
    &:hover {
      background-color: #bb1a1e !important;
    }
  }
  &.error {
    > label,
    > sub {
      color: $fieldErrorBorderColor !important;
      font-family: $baseFont !important;
    }
  }
}
