@import "../../../mixins.scss";

.wrp-loader.invoices {
  height: auto;
}

.InvoiceDetails__table {
  &.p-datatable .p-datatable-tbody > tr {
    background: $lightGray;
  }

  &.p-datatable .p-datatable-thead > tr {
    background: #dce7e7;

    > th {
      border-radius: 0;
    }
  }
  &__dispute {
    padding: 3px 0 10px;

    label {
      font-weight: 700;
      margin-right: 15px;
      min-width: 90px;
    }
  }
}

.total-amount {
  display: flex;
  justify-content: end;

  ul {
    list-style: none;
    padding-left: 0;

    p {
      display: inline-block;
      font-size: 14px;
      line-height: 21px;
      margin: 0;
      padding: 7px 20px;

      &:first-child {
        width: 160px;
        text-align: right;
      }

      &:last-child {
        min-width: 100px;
        background-color: $lightGray;
        font-weight: bold;
      }
    }
  }
}

.invoice-link {
  display: flex;
  justify-content: end;

  a {
    display: flex;

    svg {
      margin-left: 8px;
    }

    &:hover {
      text-decoration: none !important;
    }
  }
}

.InvoicesList__Table {
  .notes {
    textarea {
      opacity: 1;
      border: none;
      border-radius: 5px;
      padding: 10px;
      font-size: 14px;
      color: var(--text-color);
      background-color: $lightGray;
    }
  }
  .attachments {
    padding: 5px 1rem 5px 0;
    &__name {
      margin-left: 20px;
      color: var(--text-color);
      text-decoration: none;
    }
  }
}

.amounts-are {
  margin: 10px 0;
  text-align: right;
  cursor: auto;
}
.disclaimer-wrapper {
  width: min-content;
  min-width: 100%;
}
