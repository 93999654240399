@import "../../../mixins.scss";

.UmbrellaSelect {
  button {
    flex: 1;
  }
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0 0 0;
    list-style: none;
    padding: 0;
    li {
      font: 15px/24px "Metropolis", Helvetica, Arial, sans-serif;
      padding: 10px 30px;
      width: 100%;
      margin: 0 0 10px 0;
      background: $toggleBg;
      color: #000;
      border-radius: 10px;
      cursor: pointer;
      user-select: none;
      word-break: break-word;
      &:after {
        display: none;
      }
    }
    button {
      width: 100%;
      border: 1px solid $brandColor;
      border-radius: 10px;
      color: $brandColor;
      cursor: pointer;
      font: bold 15px/24px $baseFont;
      padding: 7px 15px;
      text-align: center;
    }
  }
}
