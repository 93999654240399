@import "../../mixins.scss";

.ResponseCard {
  margin: 10px auto 0;

  > img {
    background: #ebebeb;
    border-radius: 10px 10px 0 0;
    display: block;
    width: 100%;
    min-height: 131px;
    & + div {
      border-top: 0;
      border-radius: 0 0 10px 10px;
    }
  }
  > div {
    background: #fff;
    border: solid 1px #ebebeb;
    border-radius: 10px;
    > header {
      padding: 16px 20px 20px;
      > h2 {
        color: #111111;
        font: bold 18px/130% $baseFont;
        margin: 0 0 5px;
      }
      > p {
        color: #8e8e8e;
        font: 14px/150% $baseFont;
        margin: 0;
      }
    }
    > ul {
      margin: 0;
      padding: 0;
      list-style: none;
      > li {
        border-top: solid 1px #f6f6f6;
        color: $brandColor;
        cursor: pointer;
        font: bold 12px $baseFont;
        letter-spacing: 0.05em;
        overflow: hidden;
        padding: 0 !important;
        text-align: center;
        text-overflow: ellipsis;
        text-transform: uppercase;
        user-select: none;
        white-space: nowrap;
        > a,
        > span {
          &.disabled {
            color: #6b6b6b;
            cursor: not-allowed;
            opacity: 0.5;
          }
          padding: 16px !important;
          display: block;
        }
        > a {
          color: $brandColor;
          text-decoration: none;
        }
      }
    }
  }
}
