@import "../../mixins.scss";

.ResponseCard {
  max-width: 360px;

  &.BriefPermanent {
    font: 14px/21px $baseFont;
    margin: 5px auto;
    header {
      padding: 20px 0;
      h2,
      p {
        text-align: center;
      }

      p {
        font-weight: 400;
        padding: 0 15px;
        color: $brandColor;
      }
    }
    .brief {
      &__items {
        .row {
          background-color: $lightGray;
          margin-bottom: 1px;
          position: relative;

          &.clickable {
            .row__controls {
              div,
              label {
                cursor: pointer;
              }
              div {
                &::after {
                  // transition: all .3s ease-in;
                  display: inline-block;
                  content: "\e914";
                  color: #d4272b;
                  font: 11px "icomoon" !important;
                }
                img {
                  width: 17px;
                  height: 17px;
                  position: absolute;
                  right: 40px;
                  top: 13px;
                }
              }
            }
            &.expanded {
              h4 {
                margin: 0;
                color: #555;
              }
              p {
                margin-top: 3px;
              }
            }
            &.expanded .row__controls div::after {
              transform: rotate(90deg);
            }
          }

          &__controls {
            display: flex;
            padding: 12px 20px 8px;

            label,
            div {
              flex: 1;
              margin: 0;
            }

            div {
              text-align: right;
              font-weight: 700;
            }
          }

          &__expanded {
            transition: all 0.3s ease-in;
            padding: 0 20px;
            color: #8e8e8e;
            // Force to break string without spaces.
            white-space: pre-wrap;
            word-wrap: break-word;
            display: block;
            p:last-child {
              margin-bottom: 0;
              padding-bottom: 10px;
            }
            a {
              color: $brandColor;
              text-decoration: none;
              font-weight: 700;
              span {
                font-size: 0.8em;
              }
            }
          }
        }
      }
    }
    div {
      ul {
        display: flex;
        li {
          flex: 1;
          font-weight: 700;
          font-size: 14px;
          padding: 13px 0 !important;

          &.disabled {
            opacity: 0.5;
          }
          border-right: 1px solid #ebebeb;
          &:last-child {
            border-right: none;
          }
        }
      }
    }
  }
}
