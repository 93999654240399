@import "../../mixins.scss";
.ChatView {
  background: url("../../assets/genie-mobile-chat-bg.svg") no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 100%;
  @include for-size(tablet-landscape-up) {
    background-image: url("../../assets/genie-desktop-chat-bg.svg");
  }
  > .content {
    display: flex;
    flex-direction: column;
    height: 100%;
    position: relative;
    @include for-size(tablet-landscape-up) {
      margin-left: 70px;
    }
    > div {
      flex-grow: 1;
    }
  }
}
