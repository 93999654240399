@import "../../mixins.scss";

.SidePanel {
  align-items: center;
  background: #fff;
  border-radius: 0 0 20px 20px;
  bottom: auto;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  height: 70px;
  justify-content: space-between;
  left: 0;
  padding: 15px;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 1000;

  > .btn-burger-menu,
  > .btn-report {
    // @TODO: remove this when functionality will be ready
    visibility: hidden;
  }

  > .btn-report {
    display: none;
  }

  &__Logo {
    background: url("../../assets/genie-text-logo.svg");
    background-size: cover;
    display: block;
    height: 14px;
    width: 55px;

    @include for-size(tablet-landscape-up) {
      background-image: url("../../assets/genie-logo-inverted.svg");
      width: 40px;
      height: 40px;
      margin-bottom: 25px;
    }
  }

  @include for-size(tablet-landscape-up) {
    background: $brandColor;
    border-radius: 0 20px 20px 0;
    bottom: 0;
    box-shadow: 2px 0 20px rgba(0, 0, 0, 0.1);
    display: block;
    height: 100%;
    right: auto;
    width: 70px;

    > .btn-burger-menu {
      display: none;
    }

    > .btn-report {
      display: block;
    }

    > .btn-profile {
      bottom: 15px;
      left: 15px;
      margin-bottom: 0;
      position: absolute;
    }
  }

  > button {
    background: #efefef;
    border: 0;
    border-radius: 50%;
    color: #111;
    font-size: 40px;
    height: 40px;
    outline: none;
    padding: 0;
    width: 40px;
    cursor: pointer;
    position: relative;

    &:hover:not(.disabled) {
      background: rgba(0, 0, 0, 0.2);
      opacity: 1;

      .tooltip {
        transform: translateY(-50%) scale(1);
        opacity: 1;
        margin-left: 20px;
      }

      .big-notification {
        width: 320px;
        top: 0;
      }
    }

    @include for-size(tablet-landscape-up) {
      background: $brandColorDarken;
      color: #fff;
      margin-bottom: 25px;
    }
  }

  .info-notification {
    position: absolute;
    border-radius: 50%;
    background-color: #fff;
    color: #d4272b;
    width: 20px;
    height: 20px;
    line-height: 20px;
    top: -5px;
    right: -5px;
    text-align: center;
    font-size: 16px;
  }
}
