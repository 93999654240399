@import "../../../mixins.scss";

.GmInputNumber {
  position: relative;
  > span > input.p-inputtext.p-component {
    background: #f1f5f5;
    border: solid 2px transparent;
    border-radius: 5px;
    box-shadow: none !important;
    box-sizing: border-box;
    font: 15px/20px $baseFont;
    padding: 20px;
    width: 100%;
    &:disabled + i.icon-disabled {
      font-size: 1.1em;
      line-height: 20px;
      position: absolute;
      right: 22px;
      top: 49px;
    }
    &::placeholder {
      color: #8e8e8e;
    }
    &:-webkit-autofill {
      font-size: 15px !important;
    }
  }
  .sublabel {
    color: #8e8e8e;
    display: block;
    font: 400 13px/20px $baseFont;
    margin-top: -12px;
    margin-bottom: 20px;
  }
  &.error {
    > span > input.p-inputtext.p-component {
      border-color: $fieldErrorBorderColor;
    }
    > label,
    > sub {
      color: $fieldErrorBorderColor !important;
      font-family: $baseFont !important;
    }
  }
}
