@import "../../../mixins.scss";

@mixin collapceSign($sign) {
  td:first-child {
    &:after {
      content: $sign;
    }
  }
}

.InvoicesList {
  font-family: $baseFont;
  padding: 40px;
  overflow: auto;
  .p-component {
    font-family: $baseFont;
  }
  h1 {
    padding: 0;
    margin: 0 0 40px 0;

    @include iconBackInTitle();
  }

  .actions-buttons {
    margin-bottom: 1.5em;
  }

  .p-datatable {
    .p-datatable-thead > tr {
      > th {
        background-color: transparent;
        font-weight: 700;
      }
    }

    .p-datatable-tbody > tr {
      > td {
        font-size: 14px;
      }

      td:first-child {
        &:after {
          display: inline-block;
          background-color: #dce7e7;
          border-radius: 3px;
          width: 17px;
          text-align: center;
        }
      }

      &.collapsed,
      &.expanded {
        cursor: pointer;
      }

      &.collapsed {
        @include collapceSign("+");
      }

      &.expanded {
        @include collapceSign("-");

        td {
          border-bottom: none;
        }
      }
    }

    .p-datatable-tbody,
    .p-datatable-thead {
      > tr {
        > th,
        > td {
          padding: 0.625rem 0.625rem;
          font-size: 14px;
        }
      }
    }
  }

  .p-paginator {
    .p-paginator-pages {
      .p-paginator-page {
        &.p-highlight {
          background-color: $lightRedColor;
          border-color: $lightRedColor;
        }
      }
    }
    .p-link:focus {
      box-shadow: 0 0 0 0.2rem $primaryColor;
    }
  }

  .p-component-overlay {
    background-color: transparent;
  }

  .matching-status.circle {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-width: 3px;
    border-style: solid;
    border-radius: 50%;
    padding: 0;
    background-color: transparent;
    margin: 0 10px 0 15px;
  }

  .invoice-item {
    background-color: $lightGray;
    padding: 15px 20px 15px 0;
    margin-bottom: 10px;
    border-radius: 10px;

    header {
      font-size: 14px;
      font-weight: 700;
      display: flex;
      align-items: center;

      .status {
        display: flex;
        flex: 2;
        align-items: center;
      }

      .total {
        flex: 1;
        color: $primaryColor;
        text-align: right;
      }
    }

    article {
      margin: 7px 20px 0 45px;

      .invoice_number {
        margin-bottom: 15px;
      }
    }

    footer {
      display: flex;
      align-items: center;
      justify-content: center;

      > div {
        flex: 1;
        font-size: 13px;
        line-height: 1.5em;

        label {
          display: block;
          color: #8e8e8e;
        }
      }
    }
  }
  .p-row-expanded {
    .red-bordered {
      span {
        color: $brandColor;
      }
    }
  }

  .actions-buttons {
    @include form-button();
  }

  .p-button.p-button-text {
    color: $brandColor;
    &:enabled:hover {
      background: rgba(212, 46, 46, 0.1);
      color: $brandColor;
    }
    &:focus {
      box-shadow: none;
    }
  }
  .fixed-column {
    white-space: nowrap;
  }
}

@media screen and (max-width: 1080px) {
  .InvoicesList {
    padding: 90px 30px 30px;

    h1 {
      margin-bottom: 10px;
    }

    h2 {
      font-size: 1em;
    }

    .p-paginator {
      padding: 0;
    }

    @include iconBack();

    .p-button.p-button-text {
      position: absolute;
      top: 80px;
      right: 17px;
      background: rgba(212, 46, 46, 0.1);
      border-radius: 50%;
    }
  }

  .invoiceDetail {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100vh;
    padding: 90px 30px 30px;
    background-color: #fff;
    overflow: auto;
    z-index: 1;

    .invoiceDescription {
      border-bottom: 1px solid #e5e5e5;
      padding: 0 0 10px 0;
      margin: 0 0 20px 0;
      font-size: 14px;

      label {
        display: block;
        margin: 20px 0 10px;
        font-weight: 700;

        &:first-child {
          margin-top: 0;
        }
      }

      .detail {
        background-color: #f1f5f5;
        color: var(--text-color);
        padding: 10px;
        opacity: 1;
        font-size: 16px;
      }

      .attachments {
        a {
          color: $brandColor;
          text-decoration: none;
          display: block;
          padding: 4px 0;
        }
      }

      .dispute {
        padding: 3px 0 10px;
        &:not(:only-child) {
          padding-top: 10px;
        }
        .p-inline-message {
          align-items: flex-start;
        }
        strong {
          font-weight: 700;
          margin-right: 15px;
          min-width: 90px;
          margin-bottom: 0;
        }
      }
    }

    .invoiceTotal {
      font-size: 14px;

      .grid {
        div {
          text-align: right;
        }

        > div:last-child {
          font-weight: 700;
        }

        &:last-child {
          > div:last-child {
            color: $primaryColor;
          }
        }
      }
    }

    @include iconEdit();

    > a {
      text-decoration: none;
      color: #000;

      .icon-edit {
        background-color: $lightGray;
        top: 80px;
        right: 17px;
      }
    }

    article {
      margin-bottom: 61px;
    }

    footer {
      background-color: #fff;
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100vw;
      display: flex;
      border-top: solid 1px #e5e5e5;
      flex-direction: row-reverse;

      button {
        flex: 1;
        min-height: 60px;
        font: 700 14px $baseFont;
        text-transform: uppercase;
        color: $primaryColor;
        border-left: solid 1px #e5e5e5;

        &:last-child {
          border-left: none;
        }
      }
    }
  }

  .actions-buttons {
    margin-top: 10px;
  }
}

.matching-status {
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  padding: 5px 10px;
  border-radius: 4px;

  &.yellow {
    color: #ffba01;
    border-color: #ffba01;
    background-color: rgb(100%, 73%, 0%, 0.1);
  }

  &.gray {
    color: #8e8e8e;
    border-color: #8e8e8e;
    background-color: rgba(142, 142, 142, 0.1);
  }

  &.green {
    color: #4cbd30;
    border-color: #4cbd30;
    background-color: rgba(76, 189, 48, 0.1);
  }

  &.red {
    color: #d32f2f;
    border-color: #d32f2f;
    background-color: rgb(83%, 18%, 18%, 0.1);
  }
}
