@import "../../../mixins.scss";

.AvailabilityForm {
  width: 100%;

  .p-inputswitch {
    float: right;

    & + label {
      line-height: 24px !important;
    }
  }

  .p-selectbutton.p-buttonset.p-component {
    width: 100%;

    > .p-button {
      width: 50%;
    }
  }

  .Cal__MonthList__root {
    height: 500px !important;
  }
}
