@import "../../mixins.scss";

.ProfileIntroductionModalSlider .container .slide {
  p {
    font-weight: 400;
    font: 16px/24px $baseFont;
    color: #111111;
  }
  .ItemSelectorContainer {
    ul {
      display: block;
      max-width: 315px;
      li {
        background: #f3ebec;
        border: 0;
        border-radius: 10px;
        color: #d4272b;
        cursor: pointer;
        font: bold 15px/24px "Metropolis", Helvetica, Arial, sans-serif;
        margin: 0 0 10px 0;
        padding: 7px 15px;
        text-align: center;
        user-select: none;
        word-break: break-word;
        &:after {
          display: none;
        }
        &.addCustom:first-child:not(.prioritise) {
          background: #fff;
          border: solid 1px #e5e5e5;
        }
        &.addCustom:first-child:not(.prioritise) > input {
          background: none;
          border: 0;
          box-sizing: border-box;
          color: #d4272b;
          font: bold 15px/24px "Metropolis", Helvetica, Arial, sans-serif;
          margin: 0;
          padding: 0;
          text-align: center;
          width: 100%;
          &::placeholder {
            color: $brandColor;
          }
          &:focus::placeholder {
            color: transparent;
          }
        }
        &.selected {
          background: #d4272b;
          border-color: #d4272b;
          color: #fff;
        }
      }
    }
    .DropZone {
      min-width: 315px;
      > div {
        font: 700 15px/24px Metropolis, Helvetica, Arial, sans-serif;
        background: $primaryColor;
        border-color: $primaryColor;
        color: #fff;
        position: relative;
        padding: 7px 30px;
        margin-bottom: 10px;
        border-radius: 10px;
        > i {
          font-size: 2em;
          position: absolute;
          top: 4px;
          &.icon-menu {
            left: 0;
            color: #fff;
            &:before {
              color: #fff;
            }
          }
          &.icon-cancel {
            right: 0;
            cursor: pointer !important;
          }
        }
      }
    }
    button.p-button {
      color: $primaryColor;
      border-radius: 10px;
      border-color: $primaryColor;
      background-color: #fff;
      min-width: 315px;
      font-weight: 700;
      font-size: 15px;
      position: absolute;
      bottom: 15px;
      left: 50%;
      transform: translateX(-50%);
      z-index: 5;
      &:focus {
        box-shadow: 0 0 0 0.2rem $lightRedColor;
      }
      animation: fadeIn 0.5s;
    }
    @include for-size(phone-only) {
      button.p-button {
        min-width: auto;
        font-weight: 700;
        font-size: 15px;
        bottom: 10px;
        left: 30px;
        transform: translateX(0);
        z-index: 5;
        &:focus {
          box-shadow: 0 0 0 0.2rem $lightRedColor;
        }
        animation: fadeIn 0.5s;
      }
    }
  }
  textarea.p-inputtextarea {
    min-width: 90%;
    height: 200px;
    background-color: #f1f5f5;
    border: none;
    font: 15px/22.5px $baseFont;
    color: #111;
    padding: 20px;
    &:focus,
    &:active {
      box-shadow: none;
    }
  }
  @include for-size(phone-only) {
    margin: 0 10px;
    textarea.p-inputtextarea {
      min-width: 98%;
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
