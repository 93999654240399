@import "../../../mixins.scss";

.notification-modal {
  user-select: none;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba($color: #000000, $alpha: 0.55);
  z-index: 1100;
  .container {
    background: #fff url("../../../assets/genie-desktop-chat-bg.svg") no-repeat;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 800px;
    height: 400px;
    border-radius: 30px;
    .content {
      text-align: center;
      font-family: $baseFont;
      display: flex;
      flex-direction: column;
      margin: 60px 0;
      height: calc(100% - 120px);
      justify-content: center;
      h1 {
        font-size: 30px;
        margin: 0 0 30px 0;
        padding: 0;
      }
      p {
        font-size: 17px;
        max-width: 600px;
        margin: 0 auto 20px;
        font-weight: 400;
        font: 16px/24px $baseFont;
        color: #111111;
      }
    }
    a {
      text-transform: uppercase;
      color: #8e8e8e;
      text-decoration: none;
      font: bold 14px/14px $baseFont;
    }
    .controls {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding: 15px 40px 15px;
      position: absolute;
      bottom: 0;
      a {
        color: $primaryColor;
        display: inline-block;
        padding: 15px 20px;
        &:first-of-type {
          color: #8e8e8e;
        }
      }
    }
  }
}

@media screen and (max-width: 750px) {
  .controls a {
    margin: 0 50px;
  }
}

@include for-size(phone-only) {
  .notification-modal {
    background-color: #fff;
    .container {
      top: 0;
      left: 0;
      transform: none;
      width: 100%;
      height: 100%;
      .content {
        height: calc(100% - 120px);
        h1 {
          margin: 15px 0 15px 0;
        }
        p {
          padding: 0 20px;
        }
      }
      .controls {
        padding: 10px 10px 10px;
        a {
          margin: 0;
        }
      }
    }
  }
}
