@import "../../../mixins.scss";

.wrp-talents {
  align-items: center;
  padding: 30px;
  .p-component {
    font-family: $baseFont;
  }
  .wrp-img {
    display: block;
    height: 180px;
    transition: height 300ms ease-out;
    overflow: hidden;
    .icon-edit {
      font-size: 2.5em;
      border-radius: 2em;
      background: #fff;
      position: absolute;
      top: 0.2em;
      right: 0.2em;
      box-shadow: 0 1px 2px 0 rgb(0 0 0 / 50%);
      cursor: pointer;
    }
  }

  img {
    display: block;
    overflow: hidden;
  }

  .icon-back {
    transform: scaleX(-1);
    font-size: 35px;
    line-height: 13px;
    display: inline-block;
  }

  .p-card {
    position: relative;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    width: 340px;
    height: 425px;
    .p-card-body {
      position: absolute;
      bottom: 0;
      width: 100%;
      background: #ffffff;
      padding: 0;
      font-family: $baseFont;
      .p-card-content {
        padding-bottom: 0;
        .p-accordion-tab {
          a {
            color: $primaryColor;
            text-transform: uppercase;
            &:focus,
            &:active {
              outline: none;
              box-shadow: none;
            }
          }
          .wrp-description {
            a {
              text-decoration: none;
              font-size: 0.9em;
              display: block;
              text-transform: none;
              margin: 10px 0;
              padding: 6px 0;
            }
          }
          .p-accordion-header-link {
            display: flex;
            justify-content: space-between;
            font-size: 12px;
            text-transform: uppercase;
            border: none;
            border-bottom: 1px solid #f3f3f3;
            color: #d4272b;
            background: #fff;
            border-radius: 0;
            padding: 1rem 20px;
          }
          .p-accordion-toggle-icon {
            order: 1;
            margin-right: 0;
            font-size: 0.6rem;
          }
          .wrp-description {
            .textWrapper {
              max-height: 100px;
              overflow: auto;
              font-size: 0.9em;
            }
          }
        }
        .portfolio {
          text-align: left;

          a {
            display: block;
            font-size: 12px;
            text-transform: uppercase;
            color: #d4272b;
            text-decoration: none;
            padding: 1rem;
            font-weight: 700;

            &:hover {
              background: #dbdbdb;
              border-color: #dbdbdb;
              color: #333333;
            }
          }
        }
      }
    }

    .p-card-title {
      font-size: 18px;
      margin: 15px 20px;
      padding-right: 15px;
      .icon-edit {
        position: absolute;
        right: 0;
        font-size: 2.5em;
        cursor: pointer;
      }
      p {
        margin: 0;

        &:last-child {
          font-size: 13px;
          font-weight: normal;
          color: #848484;
        }
      }
    }

    .p-card-subtitle {
      margin: 3px 20px 0 20px;
      font-size: 13px;
      font-weight: 400;

      p {
        margin: 0;

        &:last-child {
          margin-top: 5px;
        }
      }
    }

    &.without-one-tab .p-card-body {
      padding-bottom: 43px;
    }
  }
  form {
    .p-fileupload-choose:not(.p-disabled):hover {
      background-color: $brandColor;
      border-color: $brandColor;
    }
    .field {
      .SetNoticePeriod {
        a {
          color: $brandColor;
          text-decoration: none;
          font: 15px/18px $baseFont !important;
          display: block;
          margin-bottom: 10px;
        }
      }
    }
  }
  .field .error sub {
    display: inherit;
    margin-top: 5px;
  }
}

@media (min-width: 900px) {
  .modal.ResponsePreviewEdit > .inner.right header {
    display: block;
  }
}
