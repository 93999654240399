@import "../../../mixins.scss";

.InvoiceNew {
  .InvoiceNew__form {
    &__bottom.grid {
      .GmFileUpload {
        padding: 10px 0;

        .preview {
          align-items: center;
          justify-content: center;

          > a,
          > span {
            font-size: 0.9em;
            flex: 1;
            max-width: 100%;
          }

          .p-button.p-component {
            padding: 10px;
            min-width: 43px;
          }
        }

        .p-fileupload-choose:not(.p-disabled):hover {
          background: $brandColor;
          border-color: $brandColor;
        }
      }
    }

    .p-blockui.p-component-overlay {
      background: rgb(0 0 0 / 5%);
      .lds-ring {
        padding: unset;
      }
    }

    .red-bordered {
      margin: 0.2em 0;
      span {
        color: $brandColor;
      }
    }
  }
}
